<template>
  <v-container>
    <layout :back="true" />
    <v-row class="ma-0 pa-0" justify-sm="center">
      <v-col
        cols="12"
        sm="6"
        md="4"
        class="pb-0 text-h5 text-center font-weight-medium"
        >{{ $t("terms.title") }}</v-col
      >
    </v-row>
    <v-row class="ma-0 pa-0" justify-sm="center">
      <v-col cols="12" sm="8" md="4" class="text-center text-subtitle-2">{{
        $t("terms.description")
      }}</v-col></v-row
    >
    <div id="parentNode"></div>
    <!-- <div v-for="(term,index) in termsAndConditions" :key="index">
      <h3>{{term.name}} </h3>
      <p :id="'text_'+index" @load="makeReadableText(text,`text_${index}`)"></p>
    </div> -->
    <!-- <v-list
      v-for="(term, index) in termsAndConditions"
      :key="index"
      color="transparent"
      class="ma-0 pa-0"
    >
      <v-row class="ma-0 pa-0" justify-sm="center">
        <v-col cols="12" sm="6" md="4" class="ma-0 pa-0">
          <v-list-item-content class="ma-0 pa-0"
            ><v-list-item-title class="primary--text text-h6">{{
              term.name
            }}</v-list-item-title>
            <p class="secondary--text" :ref="'text_'+index" @show="makeReadableText(term.text,'text_'+index)">
            </p>
          </v-list-item-content>
        </v-col>
      </v-row>
    </v-list> -->
  </v-container>
</template>
<script>
import Layout from "@/components/layouts/Profile.vue";
import { mapGetters, mapActions } from "vuex";
export default {
  components: {
    Layout,
  },
  watch:{
    termsAndConditions(){
      document.getElementById("parentNode").innerHTML=''
      this.termsAndConditions.map(term=>{
        this.createList(term)
      })
    }
  },
  computed: {
    ...mapGetters({ termsAndConditions: "legal/termsAndConditions" }),
  },
  mounted() {
    this.getTermsAndConditions();
  },
  methods: {
    ...mapActions({ getTermsAndConditions: "legal/getTermsAndConditions" }),
    createList(term){
      let div=document.createElement('div')
      div.className="ma-0 pa-0"
      let h3=document.createElement("h3")
      h3.innerText=term.name
      let p=document.createElement("p")
      p.innerText=term.text
      div.appendChild(h3)
      div.appendChild(p)
      document.getElementById("parentNode").appendChild(div)
    }
  },
};
</script>>